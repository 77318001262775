<template>
  <v-container
    id="my-profile"
    fluid
    tag="section"
  >
    <v-overlay :value="isOverlayVisible">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Transaction History
            </div>
          </template>

          <v-data-table
            :headers="headers"
            :items="transactions"
            sort-by="name"
            class="elevation-1"
          >
            <template v-slot:no-data>
              <p class="mb-4 mt-4">
                No transactions to display
              </p>
              <v-btn
                color="primary"
                outlined
                class="mb-4"
                @click="loadTransactions"
              >
                Refresh
              </v-btn>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'

  export default {

    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      isOverlayVisible: false,
      username: '',
      headers: [
        { text: 'Transaction Id', value: 'transactionID' },
        { text: 'Amount', value: 'amount' },
        { text: 'Date', value: 'createDate' },
      ],
      transactions: [],
      user: null,
    }),

    async created () {
      this.usermail = localStorage.getItem('username')
      await this.loadTransactions()
    },

    methods: {
      async loadTransactions () {
        try {
          this.isOverlayVisible = true
          const { data: transactionsResponse } = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}user/transactions?code=${this.apiCode}`)
          this.transactions = transactionsResponse || []
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
          throw error
        }
      },
    },
  }

</script>
